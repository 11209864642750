import React, { useContext } from "react";
import PaintballBanner from "../images/Heros/Paintball-Hero.jpg";
import { Layout, EventContainer, Seo } from "../components";
import PaintballHead from "../images/CardHeads/Paintball-Head.png";
import ProductContext from "../context/ProductContext";
import BookingCardImg from "../images/BookingImgs/Booking-CardPB.jpg";

export default function PaintballPage() {
	const jumboTexts = [
		<p>A high-intensity combat game of "tag"</p>,
		<p>Players shoot small paint-filled pellets</p>,
		<p>Best for Ages 10 and Up</p>,
	];

	const FAQTexts = [
		{
			bodyText: [
				<h4>What to wear: </h4>,
				<p>
					This is an outdoor action sport in the woods, so dress in weather
					appropriate active gear. BDU's or a hoodie and jeans, you do you.
				</p>,
				<p>
					It's a good idea for clothing to cover exposed skin and to wear good
					footwear that protects the ankle.
				</p>,
				<p>
					The paint used is non-oil based and usually washes out of clothes when
					washed in cold water with detergent.
				</p>,
			],
		},
		{
			bodyText: [
				<h4>What to bring:</h4>,
				<p>
					We provide complete rental kits, but you're welcome to bring your own.
					Please make sure your marker will chronograph and your HPA tank is
					inside its hydrostatic date. Plenty of sports drinks and water is also
					a good idea.
				</p>,
			],
		},
		{
			bodyText: [
				<h4>What to expect:</h4>,
				<p>
					Yes, paintball can sting a little. The sensation is similar to getting
					snapped with a rubber band. However, players are usually having so
					much fun they hardly notice. Protective masks are worn over the face.
					Overshooting and other forms of unsafe behavior are not permitted.
				</p>,
			],
		},
	];

	const { collections } = useContext(ProductContext);

	// Boiling collections array down to just paintball event tickets then sorting them by date created on Shopify.
	const paintballEvents = collections
		.find((collection) => collection.title === "Paintball")
		.products.filter((product) => product.productType === "Event Tickets")
		.sort((a, b) => {
			let dateA = new Date(a.createdAt),
				dateB = new Date(b.createdAt);
			return dateA - dateB;
		});

	const paintballSpecials = collections
		.find((collection) => collection.title === "Paintball Specials")
		.products.filter((product) => product.productType === "Event Tickets")
		.sort((a, b) => {
			let dateA = new Date(a.createdAt),
				dateB = new Date(b.createdAt);
			return dateA - dateB;
		});

	return (
		<>
			<Layout
				gameType='paintball'
				heroImg={PaintballBanner}
				page=''
				headerText='Nitehawk Paintball'
				bodyText={jumboTexts}
				btnText=''
				btnRoute=''
				contentsDown='form'
				defaultSport='paintball'
				FAQHeaderImg={PaintballHead}
				FAQtexts={FAQTexts}
				BookingCardImg={BookingCardImg}
				altText='Paintball Information'>
				<Seo title='Paintball' description='NiteHawk paintball' />
				<EventContainer
					headerText='Calendar Paintball Events'
					events={paintballEvents}
				/>
				<EventContainer
					headerText='Special Paintball Events and Offers'
					events={paintballSpecials}
				/>
			</Layout>
		</>
	);
}
